.top-edit-perfil-2 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 30px;
}

.top-edit-perfil-2 p {
  color: var(--blue-primary);

  font-weight: 500;
  margin: 0;
  margin-top: 10px;
}

.icon-btn-edit-perfil {
  background-color: var(--blue-primary) !important;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  color: white !important;
  width: 35px !important;
  height: 35px !important;
}

.icon-btn-edit-perfil-inact {
  background-color: var(--blue-primary) !important;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  color: white !important;
  width: 35px !important;
  height: 35px !important;
  opacity: 0.5;
}

.form-edit-perfil {
  padding: 20px 30px 0 30px;
  max-height: 400px;
  overflow: auto;

}
.form-edit-perfil::-webkit-scrollbar-track {
  margin-top: 40px;
}
.item-edit-perfil {
  display: flex;
  align-items: flex-end;
  height: 45px;
  position: relative;

}

.item-edit-perfil-pass {
  display: flex;
  align-items: flex-end;
  height: 45px;
  margin-top: 15px;
}

.icon-form-edit-perfil {
  margin-right: 10px;
  color: #e2e2e2;
}
.cont-btn-edit-perfil {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;

  position: absolute;
  width: 100%;
  bottom: 10px;
}

.cont-add-phone {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.cont-add-phone p {
  color: var(--gray-text);
  font-size: 12px;
  margin-left: 10px;
}

.btn-add-phone-perfil {
  background-color: var(--blue-primary) !important;
  width: 20px !important;
  height: 20px !important;
  color: white !important;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}

.input-mask-phone-perfil {
  outline: none;
  border: none;
  margin-bottom: 5px;
  /* background-color: aquamarine; */
  width: 100%;
  /* margin-left: 10px; */
}

.cont-phone-edit-perfil {
  /* background-color: aquamarine; */
  margin: 0;
}

.tipo-consultor-edit-perfil {
  display: flex;
  /* background-color: blue; */
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.p-tipo-consultor-edit-perfil {
  position: absolute;
  top: -5px;
  left: 33px;
  margin: 0;
  font-size: 10px;
  color: var(--gray-text);
}
.tipo-consultor-edit-perfil p {
  font-size: 12px;
  margin: 0;
  color: white;
  font-weight: 200;
}

.tipo-consultor-edit-perfil div {
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 1px 5px 40px rgba(0, 0, 0, 0.15);
  transition: 0.5s all ease-in-out;
}
.tipo-consultor-edit-perfil div:nth-child(1) {
  background-color: #919192;
}
.tipo-consultor-edit-perfil div:nth-child(2) {
  background-color: #28a4f0;
}
.tipo-consultor-edit-perfil div:nth-child(3) {
  background-color: #0081f2;
}
.tipo-consultor-edit-perfil div:nth-child(4) {
  background-color: #ebcb45;
}

.tc-active {
  transform: scale(1.15);
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.4) !important;
  transition: 0.5s all ease-in-out;
}

@media only screen and (max-width: 599px) {
  .tipo-consultor-edit-perfil {
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: -15px;
  }
  .p-tipo-consultor-edit-perfil {
    top: -20px;
  }

  .tipo-consultor-edit-perfil div {
    margin-top: 5px;
  }
}
