.contentModal {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 40px;
  margin: 5px;
  width: 100%;
  max-width: 500px;
  max-height: 100%;
  overflow: auto;
  position: relative;
}

.contentModal-image {
  width: 130px;
  margin-top: 20px;
}

.p-descp {
  font-size: 14px;
  margin: 0 0 20px 0;
  text-align: center;
  color: var(--gray-text);
  margin-top: 20px;
}

.btnControls {
  display: flex;
  justify-content: space-between;
}

.btnControlsEmail {
  display: flex;
  justify-content: space-between;
}

.btnControls .btn-step-one-emp.btnModal {
  color: var(--white);
  margin: 5px;
}

.btnControls .btn-step-one-emp.btnModal.red {
  background-color: #f50057 !important;
}

.btnControlsEmail .btn-step-one-emp.btnModal {
  color: var(--white);
  margin-left: 5vw;
}
