.cont-containers-infoAviso-of {
  /* background-color: saddlebrown; */
  height: 80%;
}
.containers-infoAviso-of {
  height: 100%;
  padding: 0 40px;
}
.cont-btn-post-infoAviso-of {
  /* background-color: sandybrown; */
  border-top: 1px solid #ededed;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1280px) and (max-width: 1619px) {
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .containers-infoAviso-of {
    height: 100%;
    padding: 0 10px;
  }


}

@media only screen and (max-width: 500px) {
}
