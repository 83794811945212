.republicar-avisos-emp {
  background-color: white;
  border-radius: 5px;
  width: 98%;
  max-width: 500px;

  position: relative;
}
.sub-republicar-avisos-emp {
  padding: 30px 40px;
}
.republicar-avisos-emp h1 {
  font-weight: 200;
  color: var(--gray-text);
  margin-bottom: 50px;
}
.dates-republicar-avisos-emp {
  display: flex;
  margin-bottom: 50px;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1280px) and (max-width: 1619px) {
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .sub-republicar-avisos-emp {
    padding: 10px;
  }
}

@media only screen and (max-width: 500px) {
}
