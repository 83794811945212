.new-card-perfil-emp {
  /* background-color: blue; */
  width: 50%;
  height: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
}
/*ITEM-1*/
.new-card-perfil-emp .item-1 {
  flex: 1;
  display: flex;
  align-items: center;
  min-height: 50px;
  /* background-color: chartreuse; */
}
.new-card-perfil-emp .item-1 div {
  display: flex;
  align-items: center;
  /* background-color: coral; */
  width: 185px;
  border-radius: 15px;
  padding: 2px 10px;
  border: 1px solid var(--blue-primary);
  /* margin-top: 10px; */
  margin-left: 10px;
}
.icon-header-perfil-new {
  color: var(--blue-primary);
}
.new-card-perfil-emp .item-1 div p {
  margin: 0;
  margin-left: 10px;
  font-size: 11px;
  color: var(--blue-primary);
}

/*Item-2*/
.new-card-perfil-emp .item-2 {
  flex: 5.5;
  /* background-color: rgb(43, 33, 182); */
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.new-card-perfil-emp .item-2 div {
  /* background-color: coral; */
  position: relative;
  display: flex;
  justify-content: center;
}
.btn-upload-image-perfil-new {
  position: absolute !important;
  background-color: var(--blue-primary) !important;
  color: white !important;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  bottom: 0;
  left: 80px;
  padding: 0 !important;
}
.user-img-perfil-new {
  width: 110px;
  height: 110px;
}

.cont-img-perfil-perfil-new {
  width: 116px;
  height: 116px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-img-perfil-perfil-new {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.new-card-perfil-emp .item-2 .p1 {
  margin: 0;
  color: var(--blue-primary);
  font-size: 18px;
}
.new-card-perfil-emp .item-2 .p2 {
  margin: 0;
  color: var(--gray-text);
  /* font-size: 18px; */
}

.new-card-perfil-emp .item-2 .consultor-perfil-new {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}

.new-card-perfil-emp .item-2 .consultor-perfil-new.empty {
  padding: 0;
  border-radius: 0;
  box-shadow: none !important;
  display: none I !important;
}

.new-card-perfil-emp .item-2 .consultor-perfil-new.training {
  background-color: #919192;
}
.new-card-perfil-emp .item-2 .consultor-perfil-new.junior {
  background-color: #28a4f0;
}
.new-card-perfil-emp .item-2 .consultor-perfil-new.semiSenior {
  background-color: #0081f2;
}
.new-card-perfil-emp .item-2 .consultor-perfil-new.senior {
  background-color: #ebcb45;
}
.new-card-perfil-emp .item-2 .consultor-perfil-new p {
  color: white;
  margin: 0;
  font-size: 14px;
}
.new-card-perfil-emp .item-2 .btn-perfil-new {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 0 40px;
}
.new-card-perfil-emp .item-2 .btn-perfil-new .btn-active {
  background-color: #1c77e1;
  padding: 5px 20px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  transition: 0.5s all ease-in-out;
}
.new-card-perfil-emp .item-2 .btn-perfil-new .btn-active p {
  color: white;
  margin: 0;
}
.new-card-perfil-emp .item-2 .btn-perfil-new .btn-inactive {
  background-color: #e4e4e4;
  padding: 5px 20px;
  box-shadow: 1px 5px 40px rgba(0, 0, 0, 0.15);
  transition: 0.5s all ease-in-out;
}
.new-card-perfil-emp .item-2 .btn-perfil-new .btn-inactive p {
  color: var(--gray-text);
  margin: 0;
}
/*item-3*/
.new-card-perfil-emp .item-3 {
  flex: 8;
  /* background-color: rgb(104, 66, 9); */
  display: flex;
  flex-direction: column;
  padding-top: 5%;
  position: relative;
  /* max-height: 300px; */
  overflow: auto;
  margin-right: 10px;
}
.new-card-perfil-emp .item-3::-webkit-scrollbar-track {
  margin-top: 30px;
}
.cont-img-perfil-new {
  background-color: var(--blue-primary);
  color: white;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-perfil-new {
  width: 20px !important;
  height: 20px !important;
}
.btn-edit-perfil-new {
  position: absolute !important;
  background-color: var(--blue-primary) !important;
  color: white !important;
  width: 40px !important;
  height: 40px !important;
  right: 30px;
  top: 30px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}
/*item-4*/
.new-card-perfil-emp .item-4 {
  flex: 1.5;
  /* background-color: rgb(27, 134, 84); */
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.new-card-perfil-emp .item-4-b {
  flex: 1.5;
  /* background-color: rgb(27, 134, 84); */
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  align-items: flex-end;
}
.btn-cv-perfil-new {
  background-color: var(--blue-primary) !important;
  color: white !important;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  width: 60px !important;
  height: 60px !important;
}
.icon-cv-perfil-new {
  width: 30px !important;
  height: 30px !important;
}
/*item-5*/
.new-card-perfil-emp .item-5 {
  flex: 2;
  /* background-color: rgb(161, 75, 40); */
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.rrss-perfil-new {
  width: 100%;
  height: 60px;
  background-color: #d9ecfd;
  border-radius: 15px;
}

/*HABILIDADES*/
.cont-habilidades-perfil-new {
  /* background-color: burlywood; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cont-habilidades-perfil-new .item-1-b {
  /* background-color: cadetblue; */
  display: flex;
  /* align-items: flex-end; */
  flex-wrap: wrap;
  /* flex: 1; */
  max-height: 350px;
  overflow: auto;
  padding: 0 20px;
}

.cont-habilidades-perfil-new .item-1-b .habilidad {
  background-color: #28a5f1;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 3px;
  padding: 0 20px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
}
.cont-habilidades-perfil-new .item-1-b .habilidad p {
  margin: 0;
  color: white;
  font-size: 14px;
}
.btn-habilidades-new-perfil {
  background-color: var(--blue-primary) !important;
  color: white !important;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  width: 40px !important;
  height: 40px !important;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1279px) and (max-width: 1619px) {
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
  .new-card-perfil-emp {
    /* background-color: blue; */
    width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  /*ITEM-1*/
  .new5card-perfil .item-1 {
    flex: 1;
    display: flex;
    align-items: center;
    /* background-color: chartreuse; */
  }
  .new-card-perfil-emp .item-1 div {
    display: flex;
    align-items: center;
    /* background-color: coral; */
    width: 185px;
    border-radius: 15px;
    padding: 2px 10px;
    border: 1px solid var(--blue-primary);
    /* margin-top: 10px; */
    margin-left: 10px;
  }
  .icon-header-perfil-new {
    color: var(--blue-primary);
    width: 15px !important;
    height: 15px !important;
  }
  .new-card-perfil-emp .item-1 div p {
    margin: 0;
    margin-left: 10px;
    font-size: 11px;
    color: var(--blue-primary);
  }

  /*Item-2*/
  .new-card-perfil-emp .item-2 {
    flex: 5.5;
    /* background-color: rgb(43, 33, 182); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .new-card-perfil-emp .item-2 div {
    /* background-color: coral; */
    position: relative;
    display: flex;
    justify-content: center;
  }
  /* .btn-upload-image-perfil-new {
    position: absolute !important;
    background-color: var(--blue-primary) !important;
    color: white !important;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    bottom: 10px;
    left: 90px;
    padding: 0 !important;
  }
  .user-img-perfil-new {
    width: 150px;
    height: 150px;
  }

  .cont-img-perfil-perfil-new {
    width: 120px;
    height: 120px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-img-perfil-perfil-new {
    width: 92px;
    height: 92px;
    border-radius: 50%;
  } */
  .new-card-perfil-emp .item-2 .p1 {
    margin: 0;
    color: var(--blue-primary);
    font-size: 16px;
  }
  .new-card-perfil-emp .item-2 .p2 {
    margin: 0;
    color: var(--gray-text);
    font-size: 14px;
    /* font-size: 18px; */
  }

  .new-card-perfil-emp .item-2 .consultor-perfil-new {
    padding: 8px;
    border-radius: 3px;
    box-shadow: 1px 5px 40px rgba(0, 0, 0, 0.15);
  }
  .new-card-perfil-emp .item-2 .consultor-perfil-new.training {
    background-color: #919192;
  }
  .new-card-perfil-emp .item-2 .consultor-perfil-new.junior {
    background-color: #28a4f0;
  }
  .new-card-perfil-emp .item-2 .consultor-perfil-new.semiSenior {
    background-color: #0081f2;
  }
  .new-card-perfil-emp .item-2 .consultor-perfil-new.senior {
    background-color: #ebcb45;
  }
  .new-card-perfil-emp .item-2 .consultor-perfil-new p {
    color: white;
    margin: 0;
    font-size: 12px;
  }
  .new-card-perfil-emp .item-2 .btn-perfil-new {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0 40px;
  }
  .new-card-perfil-emp .item-2 .btn-perfil-new .btn-active {
    background-color: #1c77e1;
    padding: 5px 20px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    transition: 0.5s all ease-in-out;
  }
  .new-card-perfil-emp .item-2 .btn-perfil-new .btn-active p {
    color: white;
    margin: 0;
  }
  .new-card-perfil-emp .item-2 .btn-perfil-new .btn-inactive {
    background-color: #e4e4e4;
    padding: 5px 20px;
    box-shadow: 1px 5px 40px rgba(0, 0, 0, 0.15);
    transition: 0.5s all ease-in-out;
  }
  .new-card-perfil-emp .item-2 .btn-perfil-new .btn-inactive p {
    color: var(--gray-text);
    margin: 0;
  }
  /*item-3*/
  .new-card-perfil-emp .item-3 {
    flex: 7;
    /* background-color: rgb(104, 66, 9); */
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    position: relative;
  }
  .cont-img-perfil-new {
    background-color: var(--blue-primary);
    color: white;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-perfil-new {
    width: 20px !important;
    height: 20px !important;
  }
  .btn-edit-perfil-new {
    position: absolute !important;
    background-color: var(--blue-primary) !important;
    color: white !important;
    width: 40px !important;
    height: 40px !important;
    right: 30px;
    top: 30px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  }
  /*item-4*/
  .new-card-perfil-emp .item-4 {
    flex: 1.5;
    /* background-color: rgb(27, 134, 84); */
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .new-card-perfil-emp .item-4-b {
    flex: 1.5;
    /* background-color: rgb(27, 134, 84); */
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    align-items: flex-end;
  }
  .btn-cv-perfil-new {
    background-color: var(--blue-primary) !important;
    color: white !important;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    width: 50px !important;
    height: 50px !important;
  }
  .icon-cv-perfil-new {
    width: 25px !important;
    height: 25px !important;
  }
  /*item-5*/
  .new-card-perfil-emp .item-5 {
    flex: 2;
    /* background-color: rgb(161, 75, 40); */
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  .rrss-perfil-new {
    width: 100%;
    height: 60px;
    background-color: #d9ecfd;
    border-radius: 15px;
  }

  /*HABILIDADES*/
  .cont-habilidades-perfil-new {
    /* background-color: burlywood; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .cont-habilidades-perfil-new .item-1-b {
    /* background-color: cadetblue; */
    display: flex;
    flex-wrap: wrap;
    /* flex: 1; */
    max-height: 250px;
    overflow: auto;
    padding: 0 20px;
  }

  .cont-habilidades-perfil-new .item-1-b .habilidad {
    background-color: #28a5f1;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 3px;
    padding: 0 15px;
  }
  .cont-habilidades-perfil-new .item-1-b .habilidad p {
    margin: 0;
    color: white;
    font-size: 12px;
  }
  .btn-habilidades-new-perfil {
    background-color: var(--blue-primary) !important;
    color: white !important;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    width: 40px !important;
    height: 40px !important;
  }
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
  .new-card-perfil-emp {
    width: 350px;
  }
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .new-card-perfil-emp {
    width: 310px;
  }
  .user-img-perfil-new {
    width: 90px;
    height: 90px;
  }

  .cont-img-perfil-perfil-new {
    width: 100px;
    height: 100px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-img-perfil-perfil-new {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }

  .new-card-perfil-emp .item-2 .btn-perfil-new {
    padding: 0 20px;
  }
  .btn-cv-perfil-new {
    width: 50px !important;
    height: 50px !important;
  }
  .new-card-perfil-emp .item-5 {
    padding: 0 10px;
  }
  .rrss-perfil-new {
    width: 100%;
    height: 50px;
   
  }
}

@media only screen and (max-width: 500px) {
}
