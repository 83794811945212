.conteiner-home {
  /* background-color: brown; */
  height: 100vh;
  width: 100%;
  padding-top: 75px;
  padding-left: 10px;
  padding-bottom: 10px;
  min-height: 850px;
}
.cont-home-big {
  /* min-height: 130vh; */
  padding-bottom: 30px;
  min-height: 135vh;
}
.cont-header-home {
  /* background-color: cadetblue; */
  height: 10%;
  padding: 5px;
}

.cont-card-perfil-home {
  /* background-color: chocolate; */
  height: 20%;
  padding: 5px;
}

.cont-cards-home {
  height: 20%;
  /* background-color: cornflowerblue; */
}

.cont-table-home-jobs {
  /* height: 100%; */
  /* height: 500px; */
  height: 50%;
  /* background-color: darkblue; */
  padding: 5px;
}

.cont-card-left-home {
  padding: 5px;
  /* height: 500px; */
  /* min-height: 400px; */
  height: 50%;
}
.cont-card-left-home .cont-card-adn-new-home {
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 1px 5px 40px rgba(0, 0, 0, 0.15);
}
.cont-card-right-home {
  padding: 5px;
  /* min-height: 400px; */
  /* height: 56%; */
  /* padding-bottom: 30px; */
  /* background-color: darkgoldenrod; */
}
.cont-card-right-home .cont-card-job-new-home {
  background-color: white;
  box-shadow: 1px 5px 40px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
}
.sub-cont-cards-home {
  /* background-color: darkgoldenrod; */
  height: 100%;
}

.items-cards-home {
  padding: 5px;
}
.adns-home-xs {
  /* background-color: lightsalmon; */
}
/* MEDIA QUERIES */
@media only screen and (min-width: 1280px) and (max-width: 1619px) {
  .cont-home-big {
    /* min-height: 100%; */
    /* background-color: rgb(105, 72, 29); */
  }
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
  .conteiner-home {
    height: 100%;
    padding-bottom: 30px;
  }
  .cont-header-home {
    height: 70px;
    padding: 5px 10px 5px 10px;
  }
  .cont-card-perfil-home {
    height: 180px;
    padding: 5px 10px 5px 10px;
  }
  .cont-card-left-home {
    padding: 5px 5px 5px 10px;
    height: 530px;
    /* height: 100%; */
    padding-top: 30px;
    /* background-color: aquamarine; */
  }
  .cont-cards-home {
    height: 300px;
    padding: 0 5px 0 5px;
    /* background-color: cornflowerblue; */
  }
  .cont-card-right-home {
    padding: 5px 10px 5px 5px;
    /* height: 530px; */
    /* background-color: darkgoldenrod; */
    padding-top: 30px;
  }
  .cont-table-home-jobs {
    height: 530px;
    /* background-color: darkblue; */
    padding: 30px 5px 5px 5px;
  }
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
  .conteiner-home {
    padding-top: 10px;
    height: 100%;
    padding-bottom: 60px;
  }

  .cont-header-home {
    /* background-color: cadetblue; */
    height: 70px;
    padding: 5px 10px 5px 10px;
  }

  .cont-card-perfil-home {
    /* background-color: chocolate; */
    height: 210px;
    padding: 5px 10px 5px 10px;
  }

  .cont-cards-home {
    height: 300px;
    /* background-color: cornflowerblue; */
    padding: 5px;
  }

  .cont-table-home-jobs {
    height: 530px;
    /* background-color: darkblue; */
    padding: 30px 5px 5px 5px;
  }

  .cont-card-left-home {
    padding: 30px 5px 5px 10px;
    height: 530px;
  }

  .cont-card-right-home {
    padding: 30px 10px 5px 5px;
    height: 530px;
    /* background-color: darkgoldenrod; */
  }

  .sub-cont-cards-home {
    /* background-color: darkgoldenrod; */
    height: 100%;
  }

  .items-cards-home {
    padding: 5px;
  }
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .conteiner-home {
    height: 100%;
    width: 100%;
    min-height: 850px;
    padding: 10px 5px 70px 5px;
  }

  .cont-card-perfil-home {
    /* background-color: chocolate; */
    height: 450px;
    padding: 5px;
  }

  .cont-cards-home {
    height: 600px;
    /* background-color: cornflowerblue; */
  }

  .cont-table-home-jobs {
    height: 500px;
    /* background-color: darkblue; */
    padding: 5px;
  }

  .cont-card-left-home {
    padding: 5px;
    height: 380px;
  }
  .adns-home-xs {
    min-height: 380px;
    height: inherit;
  }

  .cont-card-right-home {
    padding: 5px;
    /* min-height: 500px; */
    height: 380px;
    /* background-color: darkgoldenrod; */
  }
  .trabajos-home-xs {
    min-height: 380px;
    height: inherit;
  }
  .sub-cont-cards-home {
    /* background-color: darkgoldenrod; */
    height: 100%;
  }

  .items-cards-home {
    padding: 5px;
  }
  .cont-card-left-home .cont-card-adn-new-home {
    height: 380px;
  }
  .cont-card-right-home .cont-card-job-new-home {
    height: 380px;
  }
}

@media only screen and (max-width: 500px) {
}
