.datosPersonales-emp {
  /* background-color: yellow; */
  width: 100%;
  height: 100%;
}

/*top*/
.datosPersonales-emp .top-b {
  height: 270px;
  /* background-color: rebeccapurple; */
  display: flex;
  padding: 0px 50px;
}
.datosPersonales-emp .top-b .item-1 {
  flex: 1;
}
.item-datos-pers-emp-home {
  display: flex;
  margin-top: 15px;
}
.item-datos-pers-emp-home .left-dp {
  /* background-color: royalblue; */
  padding: 0 10px;
}
.item-datos-pers-emp-home .left-dp div {
  background-color: #4bc1f4;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}
.item-datos-pers-emp-home .right-dp .top-dp {
  color: var(--gray-text);
  font-size: 12px;
}
.item-datos-pers-emp-home .right-dp .bottom-dp {
  color: var(--blue-primary);
  font-size: 12px;
  margin-top: 5px;
}
/*bottom*/
.datosPersonales-emp .bottom-b {
  /* background-color: red; */
  display: flex;
  justify-content: center;
}
.btn-info-user-emp {
  background-color: var(--blue-primary) !important;
  color: white !important;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  width: 40px;
  height: 40px;
  margin-left: 5px !important;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1280px) and (max-width: 1619px) {
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .datosPersonales-emp {
    /* background-color: yellow; */
    width: 100%;
    height: 100%;
    padding: 0 10px;
    overflow: auto;
  }

  /*top*/
  .datosPersonales-emp .top-b {
    height: 260px;
    /* background-color: rebeccapurple; */
    display: flex;
    padding: 0px 10px;
    overflow: auto;
    min-width: 400px;
  }

  .item-datos-pers-emp-home .left-dp {
    /* background-color: royalblue; */
    padding: 0 10px;
  }

  /*bottom*/
}

@media only screen and (max-width: 500px) {
}
