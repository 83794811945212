.cont-card-cert {
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 20px;
  position: relative;
  margin: 20px 0 0 10px;
  min-width: 400px;
  max-width: 400px;
  min-height: 410px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: var(--white);
}

.cont-img-cert {
  background-color: #f5f5f5;
  border-radius: 50%;
  /* box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2); */
  border: 1px solid var(--gris-2);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}
.cont-img-cert img {
  width: 80%;
  margin-top: 5px;
}

.cont-btns-card-cert-b2 {
  /* background-color: rgb(17, 34, 87) !important; */
  position: absolute;
  width: 100%;
  bottom: 20px;
  padding: 0 20px;
  height: 38px;
}
.cont-icon-btns-cert-b {
  position: absolute;
  bottom: 0;
  right: 20px;
  width: 85px;
  display: flex;
  justify-content: space-between;
  /* background-color: saddlebrown; */
}

.icon-btn-cert-b {
  background-color: var(--blue-primary) !important;
  color: var(--white) !important;
  padding: 7px !important;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  position: absolute !important;
  bottom: 0;
  left: 30px;
}
.btns-card-cert {
  width: 38px;
  height: 38px;
  color: white !important;
  background-color: var(--blue-primary) !important;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}
.cont-estado-cert {
  background-color: var(--light-blue);
  padding: 8px;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}

.cont-estado-cert p {
  margin: 0;
  font-size: 12px;
}
.p1-cert {
  color: var(--blue-primary);
  margin: 0;
  font-size: 26px;
  font-weight: 200;
  max-width: 380px;
  overflow: auto;
  margin-top: 10px;
  text-align: center;
}

.p2-cert {
  color: var(--blue-primary);
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  margin: 2px 0;

  text-align: center;
}

.p3-cert {
  color: var(--light-blue);
  margin: 0;
  font-size: 12px;
  margin: 2px 0;
  text-align: center;
}

.p4-cert {
  color: var(--gray-text);
  margin: 0;
  margin-top: 15px;
  margin-bottom: 60px;
  font-size: 12px;
  font-style: italic;
  text-align: center;
  max-height: 80px;
  overflow: auto;
  max-width: 380px;

  padding: 0 20px;
}

.etiqueta-nuevo {
  position: absolute;
  background-color: var(--blue-primary);
  right: -1px;
  padding: 8px;
  padding-left: 15px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.etiqueta-nuevo p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

@media only screen and (max-width: 599px) {
  .cont-card-cert {
    width: 94%;
    min-width: inherit;
    margin: 20px 0 0 0;
  }
}
