.item-table-users-admin {
  /* background-color: seagreen; */

  height: 70px;
  border-top: 1px solid #187de277;
  display: flex;
}
.item-table-users-admin p {
  margin: 0;
}

/*item-1*/
.item-table-users-admin .item-1 {
  display: flex;
  align-items: center;
  flex: 1;
  padding-right: 5px;
}
.item-table-users-admin .item-1 div {
  background-color: var(--blue-primary);
  color: white;
  font-size: 12px;
  padding: 5px 20px;
  border-radius: 3px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  margin-right: 5px;
}
/*item-2*/
.item-table-users-admin .item-2 {
  flex: 1;
  display: flex;
  align-items: center;
}
.item-table-users-admin .item-2 .border-logo-item-emp {
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49px;
  height: 49px;
  cursor: pointer;
}
.item-table-users-admin .item-2 .img-logo {
  width: 45px;
  border-radius: 50%;
}
.item-table-users-admin .item-2 .p1 {
  color: var(--blue-primary);
  font-weight: 600;
}
.item-table-users-admin .item-2 .p2 {
  font-size: 12px;
  color: var(--gray-text);
  font-style: italic;
}
/*item-3*/
.item-table-users-admin .item-3 {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 80px;
}
.item-table-users-admin .item-3 p:nth-child(1) {
  color: var(--blue-primary);
  font-size: 14px;
  font-weight: 600;
}
.item-table-users-admin .item-3 p:nth-child(2) {
  color: var(--gray-text);
  font-size: 12px;
}
/*item-4*/
.item-table-users-admin .item-4 {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 80px;
}
.item-table-users-admin .item-4 p:nth-child(1) {
  color: var(--blue-primary);
  font-size: 14px;
  font-weight: 600;
}
.item-table-users-admin .item-4 p:nth-child(2) {
  color: var(--gray-text);
  font-size: 12px;
}
/*item-5*/
.item-table-users-admin .item-5 {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 80px;
}
.item-table-users-admin .item-5 p:nth-child(1) {
  color: var(--blue-primary);
  font-size: 14px;
  font-weight: 600;
}
.item-table-users-admin .item-5 p:nth-child(2) {
  color: var(--gray-text);
  font-size: 12px;
}
/*item-6*/
.item-table-users-admin .item-6 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 130px;
}
.item-table-users-admin .item-6 p:nth-child(1) {
  color: var(--blue-primary);
  font-size: 14px;
  font-weight: 600;
}
.item-table-users-admin .item-6 p:nth-child(2) {
  color: var(--gray-text);
  font-size: 12px;
}
/*item-7*/
.item-table-users-admin .item-7 {
  flex: 1;
  max-width: 150px;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-table-users-admin .item-7 p:nth-child(1) {
  color: var(--blue-primary);
  font-size: 14px;
  font-weight: 600;
}
.item-table-users-admin .item-7 p:nth-child(2) {
  color: var(--gray-text);
  font-size: 12px;
}
/*item-8*/
.item-table-users-admin .item-8 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.item-table-users-admin .item-8 p:nth-child(1) {
  color: var(--blue-primary);
  font-size: 14px;
  font-weight: 600;
}
.item-table-users-admin .item-8 p:nth-child(2) {
  color: var(--gray-text);
  font-size: 12px;
}
.item-table-users-admin .item-8 p:nth-child(3) {
  color: var(--gray-text);
  font-size: 12px;
}
/*item-9*/
.item-table-users-admin .item-9 {
  flex: 1;
  max-width: 150px;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
