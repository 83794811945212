.paginacion-avisos {
  display: flex;
  background-color: var(--white);
  border-radius: 5px;
  justify-content: space-between;
  overflow: hidden;
  height: 60px;
  padding: 0 30px 0 30px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
}
.left-paginacion-avisos {
  /* background-color: cadetblue; */
  display: flex;
  align-items: center;
}

.left-paginacion-avisos p {
  margin: 0;
  font-size: 20px;
  color: var(--blue-primary);
}

.right-paginacion-avisos {
  /* background-color: cadetblue; */
  display: flex;
  align-items: center;
}
