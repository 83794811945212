.cont-profesion {
  background-color: var(--white);
  padding: 40px 20px;
  border-radius: 5px;
  width: 98%;
  max-width: 700px;
  position: relative;
}
.cont-profesion h1 {
  text-align: center;
  color: var(--blue-primary);
  font-weight: 200;
  font-size: 26px;
}

.cont-estudios-profesion {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
  padding-top: 5px;
  /* background-color: brown; */
  flex: 1;
  margin-top: 40px;
  max-height: 500px;
}

.sub-cardest-profesion {
  min-width: 200px;
  margin: 5px;
}

.cont-right-profesion {
  /* background-color: brown; */
  flex: 1.3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  justify-content: center;
}

.p-select-estudio-profesion {
  margin: 0;
  color: var(--gray-text);
}

.cont-icon-profesion {
  /* background-color: brown; */
  display: flex;
  height: 40px;
  align-items: flex-end;
  margin-right: 5px;
  color: var(--blue-primary);
}

.icon-info-profesion {
  color: var(--light-blue);
  cursor: default;
}

.cont-btn-profesion {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-left: 10px;
}

.overlay-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.514);
  top: 0;
  left: 0;
  border-radius: 5px;
}
.cont-cont-estudios-profesion {
  display: flex;
}
/* MEDIA QUERIES */
@media only screen and (min-width: 1279px) and (max-width: 1619px) {
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .cont-profesion {
    background-color: var(--white);
    padding: 30px 10px;
    max-height: 550px;
    width: 96%;
  }
  .cont-cont-estudios-profesion {
    display: flex;
    flex-direction: column;
    height: 300px;
  }
  .cont-estudios-profesion {
    flex-direction: row;
    padding: 0px;
    padding-top: 5px;
    /* background-color: brown; */
    flex: 1;
    margin-top: 20px;
  }
  .cont-right-profesion {
    /* background-color: brown; */
    flex: 0.8;
    justify-content: flex-start;
    padding-top: 25px;
 

  }
}

@media only screen and (max-width: 433px) {
}
