.cont-three-adn {
  background-color: var(--white);
  border-radius: 5px;
  width: 100%;
  max-width: 550px;

  min-height: 400px;
  position: relative;
  border-radius: 5px;
  width: 100%;
  height: 98%;
  max-height: 640px;
  min-height: inherit;
  /* padding: 5px 0px; */
  padding-bottom: 10px;
  /* background-color: mediumblue; */
  /* overflow: auto; */
}
.sub-cont-three-adn {
  /* background-color: brown; */
  width: 100%;
  height: 100%;
  max-height: 620px;
  min-height: 400px;
  max-width: inherit;
  padding: 20px 40px;
  /* overflow: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  /* background-color: mediumorchid; */
}
.cont-btn-adn-one-custom {
  display: flex;
  width: 100%;
  /* background-color: blue; */
  justify-content: space-between;
}
.modulos-three-adn {
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0 0 0;
  max-width: 250px;
  justify-content: center;
  /* background-color: midnightblue; */
}

.sub-modulo-three-adn {
  width: 50px;
  height: 50px;
  background: var(--degradado-blue);
  margin: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.5s all ease-in-out;
  opacity: 0.5;
}
.sub-modulo-three-adn-active {
  width: 50px;
  height: 50px;
  background: var(--degradado-blue);
  margin: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.5s all ease-in-out;
}

.sub-modulo-three-adn p {
  margin: 0;
  font-size: 8px;
  text-align: center;
}

.sub-modulo-three-adn-active p {
  margin: 0;
  font-size: 8px;
  text-align: center;
}

.p1-three-adn {
  color: var(--blue-primary);
  font-size: 18px;
  text-align: center;
  margin: 25px 0;
}

.cont-nivel-adn {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
}

.sub-nivel-adn {
  width: 80px;
  height: 80px;
  margin-left: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}

.sub-nivel-adn p {
  margin: 0;
  text-align: center;
  font-size: 12px;
}

.cont-nivel-adn-three {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 0 20px;
}

.sub-nivel-adn-active {
  width: 90px;
  height: 90px;
  margin-left: 10px;
  border-radius: 10px;
  border: 4px solid #555454;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.4);
}

.sub-nivel-adn-active p {
  margin: 0;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1279px) and (max-width: 1619px) {
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
  .cont-three-adn {
    background-color: var(--white);
    border-radius: 5px;
    width: 100%;
    height: 98%;
    max-height: 620px;
    min-height: inherit;
    padding: 20px 20px;
    /* background-color: blue; */
    overflow: auto;
  }
  .sub-cont-three-adn {
    /* background-color:brown;  */
    width: 100%;
    height: 100%;
    /* max-height: 620px; */
    min-height: 650px;

    /* overflow: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* position: relative; */
  }
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .cont-three-adn {
    background-color: var(--white);
    border-radius: 5px;
    width: 98%;
    height: 98%;
    max-height: 620px;
    min-height: inherit;
    min-width: inherit;
    max-width: inherit;
    padding: 0;
    position: relative;
    overflow: auto;

    display: inherit;
    flex-direction: column;
    align-items: inherit;
    /* padding: 20px 40px; */
  }
  .sub-cont-three-adn {
    /* background-color:brown;  */
    width: 100%;
    height: 100%;
    max-height: 620px;
    min-height: 600px;
    min-width: 450px;
    width: 550px;
    max-width: inherit;
    padding: 0;
    margin-left: 150px;
    /* overflow: auto; */
    display: flex;
    flex-direction: column;
    align-items: inherit;
    position: relative;
  }
}

@media only screen and (max-width: 433px) {
  .sub-cont-three-adn {
    /* background-color:brown;  */
    width: 100%;
    height: 100%;
    max-height: 620px;
    min-height: 400px;
    min-width: 450px;
    width: 550px;
    max-width: inherit;
    padding: 0;
    margin-left: 200px;
    /* overflow: auto; */
    display: flex;
    flex-direction: column;
    align-items: inherit;
    position: relative;
  }
}
