.perfiles-view-emp-admin {
  /* background-color: royalblue; */
  height: 100%;
  padding: 40px;
  padding-top: 20px;
}
.perfiles-view-emp-admin .cont-loader-perfiles-emp-admin {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.perfiles-view-emp-admin p {
  margin: 0;
}

.perfiles-view-emp-admin .column {
  overflow: auto;
  max-height: 280px;
}
.perfiles-view-emp-admin .column::-webkit-scrollbar-track {
  margin-top: 10px;
}
.perfil-view-emp-admin {
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  margin: 10px;
  border-radius: 5px;
  padding: 20px;
}
/*ROW 1*/
.perfil-view-emp-admin .row-1 {
  display: flex;
  flex-direction: column;
}
.perfil-view-emp-admin .row-1 .p1 {
  color: var(--blue-primary);
  text-align: center;
}
.perfil-view-emp-admin .row-1 .p2 {
  color: var(--gray-text);
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
}
/*ROW 2*/
.perfil-view-emp-admin .row-2 {
  display: flex;
}
.perfil-view-emp-admin .row-2 .cont-item {
  flex: 1;
}
/*ROW 3*/
.perfil-view-emp-admin .row-3 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.perfil-view-emp-admin .row-3 .left {
  display: flex;
  align-items: center;
}
.perfil-view-emp-admin .row-3 .left .caducado {
  background-color: sandybrown;
  color: white;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 3px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}
.perfil-view-emp-admin .row-3 .left .activo {
  background-color: var(--blue-primary);
  color: white;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 3px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}
.perfil-view-emp-admin .row-3 .right {
  display: flex;
  align-items: center;
}
