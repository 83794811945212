.conteiner-login-emp {
  background-image: url("../../resources/empresas/ZAPTalent-Background.png");
  min-height: 100vh;
  width: 100%;

  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cont-login-emp img {
  width: 120px;
}
.cont-input-login-emp {
  /* background-color: royalblue; */
  display: flex;
  flex-direction: column;
  flex: 3;
  justify-content: space-between;
  min-height: 150px;
  /* padding-bottom: 5px; */
}
.cont-card-login-emp {
  width: 95%;
  max-width: 700px;
  height: 60%;
  max-height: 500px;
  /* background-color: var(--white); */
  background-color: aqua;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex: 1;
  opacity: 0;
  animation: animationLogin 2s ease-in-out forwards;
  margin-top: 10px;
  /* overflow: hidden; */
}

.left-card-login-emp {
  flex: 2.5;
  display: flex;
  border: 2px solid #9ec9e0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10px;
  justify-content: space-around;
  flex-direction: column;
  background-color: brown;
}

.right-card-login-emp {
  background-image: url("../../resources/empresas/ZAPTalent-Login-EmpresaModal.png");
  display: flex;
  background-size: cover;
  background-position: center;
  flex: 1.7;
  flex-direction: column;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  /* border: 2px solid #4f6470; */
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.right-card-login-emp h1 {
  color: white;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}
.right-card-login-emp p {
  text-align: center;
  font-size: 12px;
}

.cont-left-item-emp {
  background-color: aqua;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.btn-cancelar-login-emp {
  background-color: var(--orange) !important;
  justify-content: center !important;
  max-width: 110px !important;
  max-height: 45px !important;
  height: 45px;
  border-radius: 5px !important;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2) !important;
}
.btn-cancelar-login-emp p {
  margin: 0 !important;
  font-size: 12px;
}
.btn-recuperar-login-emp {
  background-color: #1074ba !important;
  width: 120px !important;
  border-radius: 5px !important;
  height: 45px !important;
  justify-content: center !important;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2) !important;
}

.btn-recuperar-login-emp p {
  color: var(--white) !important;
  font-size: 12px !important;
  margin: 0;
}
@media (max-width: 700px) {
  .right-card-login-emp {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 0;
    min-height: 500px;
  }
}

.btn-registrate-login.naranja{
  background-color: #FF861B !important;
}


@media (min-width: 1600px) {
  .card-login {
    width: 95%;
    max-width: 1000px;
    height: 60%;
    max-height: 700px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    flex: 1;
    opacity: 0;
    animation: animationLogin 2s ease-in-out forwards;
  }
  .card-login .cont-input-login-emp {
    max-height: 200px;
  }
  .card-login .cont-left-item .cont-top-login p {
    color: var(--blue-primary);
    font-size: 30px;
    margin: 5px;
  }

  .card-login .cont-btn-ing-can-login {
    display: flex;
    flex-direction: row;
    padding-right: 0px;
    height: 60px;
    justify-content: space-between;
    align-items: center;
  }
  .card-login .btn-cancelar-login-emp {
    background-color: var(--blue-dark) !important;
    justify-content: center !important;
    max-width: 220px !important;
    color: white !important;
    max-height: 65px !important;
    height: 65px;
    border-radius: 5px !important;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2) !important;
  }
  .card-login .btn-ingresar-login {
    background-color: var(--blue-primary) !important;
    max-width: 220px !important;
    height: 65px;
    max-height: 65px !important;
    border-radius: 5px !important;
    justify-content: center !important;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2) !important;
  }
  .card-login .btn-ingresar-login p {
    margin: 0 !important;
    font-size: 14px;
  }
  .card-login .btn-cancelar-login-emp p {
    margin: 0 !important;
    font-size: 14px;
  }
  .card-login .card-login .MuiInputBase-input {
    font-size: 14px !important;
    height: 1.5em;
  }
  .card-login .form-control-login {
    max-height: 60px !important;
    /* height: 45px !important; */
    background-color: #f3f8fe !important;
  }
  .card-login .input-login .MuiInputBase-input {
    height: 1.3em;
  }
  .card-login .btn-recuperar-login-emp {
    background-color: var(--green) !important;
    width: 220px !important;
    border-radius: 5px !important;
    height: 65px !important;
    justify-content: center !important;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2) !important;
  }
  
  .card-login .btn-recuperar-login-emp p {
    color: var(--white) !important;
    font-size: 14px !important;
    margin: 0;
  }
  .card-login .right-card-login-emp{
    padding-left: 10px;
    padding-right: 10px;
  }
}
