.conteiner-login {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-image: url("../../resources/img/bg-welcome.webp");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.conteiner-login::before {
  content: "";
  /*background-color: rgba(0, 0, 0, 0.3);*/
  width: 100%;
  height: 100%;
  position: absolute;
}
.conteiner-login h1:nth-child(1) {
  font-size: 22px;
  margin-bottom: 20px;
  z-index: 9;
  color: white;
  text-shadow: 2px 2px 2px#00000038;
}
.conteiner-login h1:nth-child(3) {
  font-size: 27px;
  margin-bottom: 20px;
  margin-top: 20px;
  z-index: 9;
  color: white;
  text-shadow: 2px 2px 2px#00000038;
}
.logo-login-fake {
  width: 200px;
  z-index: 9;
}


