.container-users-admin {
    padding-top: 75px;
    padding-left: 15px;
    height: 100vh;
    padding-right: 15px;
    display: flex;
    max-width: 1500px;
    flex-direction: column;
  }
  
  .container-users-admin .top {
    flex: 1;
  }
  
  .container-users-admin .center {
    flex: 2;
    min-height: 140px;
    display: flex;
    flex-wrap: wrap;
    /* background-color: brown; */
    justify-content: space-between;
    padding: 10px 0 10px 0;
  }
  .container-users-admin .center .item {
    flex: 1;
    padding: 0 5px 0 5px;
    max-width: 400px;
    /* min-width: 300px; */
    min-height: 100px;
  }
  .container-users-admin .bottom {
    flex: 10;
    padding-bottom: 10px;
  }
  
  .date-right-estudio_ {
    flex: 1;
    padding-left: 5px;
  }