.card-table-home-emp {
  width: 100%;
  margin-top: 10px;
  background-color: var(--white);
  box-shadow: 1px 5px 40px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  min-height: 137px;
  display: flex;
  position: relative;
  flex-direction: column;
}
.card-table-home-emp .cont-estado-no-leido {
  width: 15px;
  height: 15px;
  background-color: var(--light-blue);
  position: absolute;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5px;
  border-radius: 50%;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
}
.card-table-home-emp .cont-estado-no-leido .icon-view-no-leido {
  width: 13px;
  height: 13px;
  color: white;
}
.sub-card-table-home-emp {
  display: flex;
}
.sub-card-table-home-emp-bottom {
  border-top: 1px solid rgb(230, 230, 230);
  padding: 10px 0 10px 23px;
  display: flex;
  align-items: flex-end;
}
.sub-card-table-home-emp-bottom .icono-aviso-card-table {
  color: var(--gray-text);
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.sub-card-table-home-emp-bottom p {
  color: var(--gray-text);
  font-style: italic;
  font-size: 14px;
}
.no-posee-card-table-home-emp {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: var(--gray-text);
  font-size: 12px;
}
/*item-1*/
.card-table-home-emp .item-1 {
  flex: 0.5;
  /* background-color: rgb(231, 181, 181); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-table-home-emp .item-1 .cont-imagen {
  width: 60px;
  background-color: white;
  height: 60px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
}

.card-table-home-emp .item-1 .cont-imagen img {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-size: cover;
}

/*item-2*/
.card-table-home-emp .item-2 {
  flex: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: rgb(187, 236, 236); */
}
.card-table-home-emp .item-2 .p1 {
  color: var(--blue-primary);
  font-weight: 500;
  cursor: pointer;
}
.card-table-home-emp .item-2 .p2 {
  color: var(--gray-text);
  font-size: 14px;
  font-weight: 200;
}
.tipo-cons-home-post-emp {
  background-color: #197ee6;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  border-radius: 3px;
  margin-top: 10px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
}
.consultor-senior-table-home-emp {
  background-color: #ebcb45;
}
.tipo-cons-home-post-emp p {
  color: white;
  font-size: 12px;
}
/*item-3*/
.card-table-home-emp .item-3 {
  flex: 1;
  /* background-color: rgb(225, 205, 243); */
}
.card-table-home-emp .item-3 .p1 {
  font-size: 14px;
  color: var(--blue-primary);
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
}
.swip-home-emp {
  display: flex;
  flex-direction: column;
}
.stepper-user-home-emp {
  flex: 1;
  display: flex;
  margin-top: -8px;
  justify-content: center;
}
.swip-home-emp .top-swip-home-emp {
  display: flex;
  /* background-color: chocolate; */
  height: 40px;
}
.swip-home-emp .top-swip-home-emp .left-swip-home-emp {
  /* background-color: coral; */
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swip-home-emp .top-swip-home-emp .center-swip-home-emp {
  /* background-color: rgb(104, 235, 99); */
  flex: 2.3;
  max-width: 100px;
  min-width: 100px;
}
.swip-home-emp .top-swip-home-emp .right-swip-home-emp {
  /* background-color: rgb(126, 217, 240); */
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-swip-home-emp {
  width: 15px !important;
  height: 15px !important;
  background-color: #4bacef !important;
  box-shadow: 1px 1px 5px 1x rgba(0, 0, 0, 0.2);
}
.btn-swip-home-emp .icon-swip-home-emp {
  width: 10px;
  color: white;
}

.swip-cont-home-emp {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* max-width: 100px; */
  /* background-color: rosybrown; */
  height: 40px;
}
.swip-cont-home-emp .swip-item-home-emp {
  background: var(--degradado-blue);
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  border-radius: 3px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}
.swip-cont-home-emp p {
  color: white;
  font-size: 12px;
  overflow: hidden;
}

/*item-4*/
.card-table-home-emp .item-4 {
  flex: 1;
  /* background-color: rgb(235, 198, 172); */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card-table-home-emp .item-4 div {
  display: flex;
  align-items: center;
}

.card-table-home-emp .item-4 .icon-user-home-emp {
  width: 20px;
  color: var(--blue-primary);
}

.card-table-home-emp .item-4 div p {
  font-size: 12px;
  color: var(--gray-text);
  margin-left: 10px;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1280px) and (max-width: 1619px) {
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .card-table-home-emp {
    min-height: inherit;
    padding: 10px 0;
  }
  .sub-card-table-home-emp {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sub-card-table-home-emp-bottom {
    align-items: center;
  }
  .card-table-home-emp .item-4 {
    padding-left: 20px;
    padding-top: 20px;
  }
  .card-table-home-emp .item-2 .p1 {
    text-align: center;
  }
  .card-table-home-emp .item-2 .p2 {
    text-align: center;
  }
}

@media only screen and (max-width: 500px) {
}
