.five-add-emp-admin {
  background-color: white;
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  position: relative;
  height: 500px;
  padding: 30px 0;
}

.five-add-emp-admin p {
  margin: 0;
  color: var(--gray-text);
  font-size: 14px;
  text-align: center;
}
