.input-label-custom-input {
  font-size: 12px !important;
}

.input-custom-input {
  font-size: 12px !important;
}

.helper-text-custom-input {
  font-size: 11px !important;
  margin: 0 !important;
}
