.cont-section-three {
  height: 90vh;
  max-height: 700px;
  background-image: url("../../../../../resources/cover/ZAPTalent-Section-Benefits.png");
  background-size: cover;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
}
.sub-section-three {
  width: 100%;
  max-width: 1000px;
  /* background-color: salmon; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.overlay-section-three {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.cont-section-three h1 {
  color: var(--white);
  margin-right: 10%;
  z-index: 9;
}

.cont-section-three p {
  color: var(--white);
  max-width: 400px;
  text-align: right;
  font-size: 12px;
  margin-right: 10%;
  z-index: 9;
}

.btn-start-section-three {
  background-color: var(--blue-primary) !important;
  width: 200px !important;
  margin-right: 10% !important;
  border-radius: 50px !important;
  justify-content: center !important;
  margin-top: 20px !important;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1023px) {
  .sub-section-three {
    width: 100%;
    max-width: 1000px;
    
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .cont-section-three h1 {
    color: var(--white);
    text-align: center;
    margin-right: 0;
  }

  .cont-section-three p {
    color: var(--white);
    max-width: 400px;
    text-align: center;
    font-size: 12px;
    margin-right: 0;
    margin: 10px 10px;
  }
  

  .btn-start-section-three {
    margin-right: 0 !important;
   height: 40px;
  }
  
}

/*  Smartphones in landscape mode */
