.cont-card-b-perfil {
  width: 100%;
  height: 100%;
  min-height: 120px;
  max-height: 120px;
  margin: 5px 0 10px 0;
  border-radius: 5px;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.15);
  display: flex;
}
.type-a-perfil {
  background: var(--degradado-blue);
  color: white;
}

.type-b-perfil {
  background-color: var(--white);
}
.text-card-b-perfil {
  color: var(--blue-primary);
}

.cont-card-b-perfil p {
  margin: 0;
  font-size: 18px;
}

.cont-card-b-perfil div:nth-child(1) {
  display: flex;
  flex: 2;
  justify-content: flex-start;
  padding-left: 30px;
  align-items: center;
}

.cont-card-b-perfil div:nth-child(2) {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.btn-card-a-perfil {
  background-color: var(--white) !important;
  color: var(--blue-primary) !important;
  width: 40px !important;
  height: 40px !important;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2) !important;
}

.btn-card-b-perfil {
  background-color: var(--blue-primary) !important;
  color: var(--white) !important;
  width: 40px !important;
  height: 40px !important;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2) !important;
}

@media only screen and (max-width: 599px) {
  .cont-card-b-perfil {
    min-height: 150px;
  }
}
