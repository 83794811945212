.cont-reg-b {
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 40px 90px 40px 90px;
  width: 95%;
  max-width: 450px;
  position: relative;
}

.top-reg-b {
  /* background-color: darkcyan; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.center-reg-b {
  display: flex;
  flex-direction: column;
  /* background-color: darkgreen; */
  flex: 1;
  justify-content: space-around;
}
.center-3{
  display: flex;
  /* display: flex; */
  height: 55px;
  align-items: center;
}
.bottom-reg-b {
  display: flex;
  /* background-color: darkmagenta; */
  flex: .5;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

@media only screen and (max-width: 425px) {
  .cont-reg-b {
    padding: 40px 20px 40px 20px;
  }
}
 

