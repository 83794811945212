.contenedor-main {
  margin-left: 250px;
  min-height: 100vh;
  display: flex;
  transition: 0.5s all ease-in-out;

  /* background: var(--main-bg); */
}

.contenedor-main-mini {
  margin-left: 80px;
  min-height: 100vh;
  display: flex;
  transition: 0.5s all ease-in-out;

  /* background: var(--main-bg); */
}

.contenedor-main main {
  width: 100%;
  height: 100%;
}

.contenedor-main-mini main {
  width: 100%;
  height: 100%;
}
.splash-screen-responsivo {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  background: var(--degradado-blue);
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.splash-screen-responsivo img {
  width: 130px;
  height: 130px;
}
.splash-screen-responsivo p {
  color: white;
  margin: 0; 
}
.splash-screen-responsivo p:nth-child(2) {
  font-size: 22px;
  margin-top: 20px;
}
@media only screen and (max-width: 959px) {
  .contenedor-main {
    margin: 0;
  }
  .splash-screen-responsivo {
    display: flex;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    overflow: hidden;
    background-color: cadetblue;
  }

  .contenedor-main-mini {
    margin: 0;
  }
}

.li-active.icon-sidebar {
  color: white !important;
}
