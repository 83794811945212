.eco-sap-empresas {
  padding-top: 70px;
  /* padding-left: 10px; */
  padding-right: 10px;
  /* max-width: 1700px; */
}
.eco-sap-empresas p {
  margin: 0;
}
.top-eco-sap-emp {
  display: flex;
}
.header-eco-sap-emp {
  flex: 6;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 30px;
}
.space-top-eco-sap-emp {
  flex: 2;
  max-width: 300px;
}
.bottom-eco-sap-emp {
  /* background-color: brown; */
  display: flex;
}
.content-eco-sap {
  /* background-color: cadetblue; */
  flex: 6;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  min-height: 83vh;
  max-height: 83vh;
  overflow: auto;
  padding-left: 15px;
  padding-right: 20px;
  padding-bottom: 45px;
  padding-top: 10px;
}
.content-eco-sap::-webkit-scrollbar-track {
  margin-top: 25px;
}
.filtros-eco-sap-emp {
  /* background-color: chocolate; */
  flex: 2;
  max-width: 300px;
  padding: 20px 10px 0 10px;
}

.act-plan-msg-eco {
  /* background-color: blue; */
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.act-plan-msg-eco p:nth-child(1) {
  color: var(--blue-primary);
  font-size: 35px;
}

.act-plan-msg-eco p:nth-child(2) {
  color: var(--gray-text);
  font-size: 20px;
  font-weight: 200;
}

@media (max-width: 1500px) {
  .space-top-eco-sap-emp {
    display: none;
  }
  .filtros-eco-sap-emp {
    display: none;
  }
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1280px) and (max-width: 1619px) {
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
  .eco-sap-empresas {
    padding-top: 70px;
    /* padding-left: 10px; */
    padding-right: 0px;
    height: 100%;
    /* max-width: 1700px; */
    /* background-color: lawngreen; */
  }
  .eco-sap-empresas p {
    margin: 0;
  }
  .top-eco-sap-emp {
    display: flex;
  }
  .header-eco-sap-emp {
    flex: 6;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .bottom-eco-sap-emp {
    /* background-color: brown; */
    display: flex;
  }
  .content-eco-sap {
    /* background-color: cadetblue; */
    flex: 6;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    min-height: 83vh;
    max-height: 83vh;
    overflow: auto;
    padding-left: 15px;
    padding-right: 20px;
    padding-bottom: 70px;
    padding-top: 10px;
  }
  .content-eco-sap::-webkit-scrollbar-track {
    margin-top: 25px;
  }
  .filtros-eco-sap-emp {
    /* background-color: chocolate; */
    flex: 2;
    max-width: 300px;
    padding: 20px 10px 0 10px;
  }

  .act-plan-msg-eco {
    /* background-color: blue; */
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .act-plan-msg-eco p:nth-child(1) {
    color: var(--blue-primary);
    font-size: 35px;
  }

  .act-plan-msg-eco p:nth-child(2) {
    color: var(--gray-text);
    font-size: 20px;
    font-weight: 200;
  }
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .eco-sap-empresas {
    padding-top: 70px;
    /* padding-left: 10px; */
    padding-right: 0px;
    /* max-width: 1700px; */
    height: 100%;
  }
  .eco-sap-empresas p {
    margin: 0;
  }
  .top-eco-sap-emp {
    display: flex;
  }
  .header-eco-sap-emp {
    flex: 6;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .space-top-eco-sap-emp {
    flex: 2;
    max-width: 300px;
  }
  .bottom-eco-sap-emp {
    /* background-color: brown; */
    display: flex;
  }
  .content-eco-sap {
    /* background-color: cadetblue; */
    flex: 6;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    min-height: 83vh;
    max-height: 83vh;
    overflow: auto;
    padding-left: 15px;
    padding-right: 20px;
    padding-bottom: 100px;
    padding-top: 10px;
  }
  .content-eco-sap::-webkit-scrollbar-track {
    margin-top: 25px;
  }
  .filtros-eco-sap-emp {
    /* background-color: chocolate; */
    flex: 2;
    max-width: 300px;
    padding: 20px 10px 0 10px;
  }

  .act-plan-msg-eco {
    /* background-color: blue; */
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .act-plan-msg-eco p:nth-child(1) {
    color: var(--blue-primary);
    font-size: 35px;
  }

  .act-plan-msg-eco p:nth-child(2) {
    color: var(--gray-text);
    font-size: 20px;
    font-weight: 200;
  }
}

@media only screen and (max-width: 500px) {
}


.product-labels {
 /* align-items: center;*/
  display: flex;
  /*flex-wrap: wrap;*/
  gap: 5px 10px;
  white-space: nowrap;
}

.product-support-rating {
  align-items: center;
  border: 1px solid var(--light-blue);
  border-radius: 40px;
  display: flex;
  min-height: 26px;
  padding: 0 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--light-blue);
}

.cvs-span {
  font-family: "Roboto";
  color: #fff;
  font-size: 12px;
}


.cont-header-sap-descarga{
  display: inline-block;
  border-radius: 36%;
  margin-right: 5px;
  background-color: transparent;
}

.alignDescarga{
  text-align: right;
  margin-top: 10px;
  padding-bottom: 5px;
}