.dialog-pdfcv-eco-emp {
  width: 260px;
  height: 200px;
  position: relative;
  background-color: white;

  display: flex;
  flex-direction: column;
}

.dialog-pdfcv-eco-emp .item-1 {
  /* background-color: brown; */
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}
.item-1-error-pdf-sv {
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}
.error-pdf-cvusuario {
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}
