.cont-form-one-adn {
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
  margin: 10px 5px;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p1-form-adn-one {
  margin: 0;
  font-size: 20px;
}

.cont-name-one-form {
  width: 50px;
  height: 50px;
  background: var(--degradado-blue);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}
