.cont-reenviar-email {
  /* background-color: blueviolet; */
  display: flex;
  min-height: 90vh;
  overflow: hidden;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cont-reenviar-email h1 {
  text-align: center;
  color: var(--blue-primary);
  max-width: 600px;
  margin-bottom: 30px;
}
