.cont-reg-c {
  background-color: var(--white);
  border-radius: 5px;
  /* padding: 40px 90px 40px 90px; */
  padding: 40px 0 0 0;
  width: 95%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  position: relative;
  /* background-color: aqua; */
}

.top-reg-c {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  flex: 2.5;
  align-items: center;
  justify-content: space-around;
}

.center-reg-c {
  /* background-color: rgb(11, 138, 53); */
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: space-around;
}
.bottom-reg-c {
  /* background-color: rgb(159, 172, 163); */
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.center-reg-c select {
  height: 40px;
  padding-left: 10px;
  width: 100%;
  outline: none;
  background-color: var(--blue-extralight);
  border-radius: 5px;
  border: 1px solid var(--light-blue-3);
}

@media only screen and (max-width: 425px) {
  .cont-reg-c {
    padding: 40px 20px 40px 20px;
  }
}
