.cont-custom-select {
  /* background-color: darkorchid; */
  width: 100%;
  position: relative;
}
.cont-custom-select-error{
  width: 100%;
  position: relative;
  min-height: 45px;
}
.custom-select-form {
  background-color: #f3f8fe !important;
}
.label-custom-select {
  font-size: 12px !important;
}

.select-custom-select {
  font-size: 12px !important;
}
.form-helper-select {
  background-color: #fff3f2;
  font-size: 10px !important;
  border: 1px solid#FEB0A9;
  width: 100%;
  margin: 0 !important;
  border-top: none;
  margin-top: -2px !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 18px;
  display: flex !important;
  align-items: center !important;
  padding-left: 15px;
  transition: 0.5s all ease-in-out;
  /* z-index: -1; */
  position: absolute;
  bottom: -6px;
  color: red !important;
}

.form-helper-select-inact {
  background-color: #fff3f2;
  font-size: 10px !important;
  border: 1px solid#FEB0A9;
  width: 100%;
  margin: 0 !important;
  border-top: none;
  margin-top: -33px !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 20px;
  display: flex !important;
  align-items: center !important;
  padding-left: 15px;
  /* z-index: -1; */
  transition: 0.5s all ease-in-out;
  position: absolute;
  bottom: 0;
  color: red;
}
