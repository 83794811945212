.cont-sidebar {
  height: 100vh;
  position: fixed;
  width: 250px;
  background-color: var(--body-sidebar);
  z-index: 100;
  display: relative;
  overflow: hidden;
  transition: 0.5s ease-in-out;
  /* background-color: seagreen; */
  /* box-shadow: inset 0 0 15px 10px #2e2d41, 0.5px 0 10px 1px rgb(58, 58, 58); */
  box-shadow: 0.5px 0 10px 1px rgb(58, 58, 58);
}

.cont-sidebar-mini {
  height: 100vh;
  position: fixed;
  width: 80px;
  overflow: hidden;
  background-color: var(--body-sidebar);
  z-index: 100;
  display: relative;
  transition: 0.5s ease-in-out;
  /* box-shadow: inset 0 0 15px 10px #323147, 0.5px 0 10px 1px rgb(58, 58, 58); */
  box-shadow: 0.5px 0 10px 1px rgb(58, 58, 58);
}
.header-logo-sidebar {
  position: absolute;
  width: 100%;
  height: 75px;
  top: 0;
  left: 0;
  background: var(--degradado-blue);
}
.header-logo-sidebar img {
  /* background-color: salmon; */
  margin: 0;
  width: 220px;
  /* height: 230px; */
  margin-top: 4px;
  margin-left: 10px;
}
.header-sidebar {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  flex: 2;
  padding: 0 !important;
  height: 235px;
  transition: 0.5s ease-in-out !important;
  transition-delay: 1s all !important;
}
.header-sidebar-mini {
  display: flex;
  flex: 2;
  padding: 0 !important;
  height: 70px;
  transition: 0.5s ease-in-out !important;

  /* background-color: salmon !important; */
}
.active-header {
  background: #204371 !important;
  transition: 0.5 ease-out;
}
.foto-perfil {
  width: 100px !important;
  height: 100px !important;
  transition: 0.5s ease-in-out;
  border-radius: 50%;
  /* transition-delay: .2s ; */
}
.cont-img-perfil-sidebar {
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 106px;
  height: 106px;
  margin-bottom: 10px;
  transition: 0.5s all ease-in-out;
  box-shadow: 1px 1px 5px 1px rgb(0, 0, 0, 0.2);
  /* transition-delay: .2s ; */
}
.cont-img-perfil-sidebar-mini {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 51px;
  height: 51px;
  margin-bottom: 10px;
  transition: 0.5s all ease-in-out;
  margin-top: 10px;
  box-shadow: 1px 1px 5px 1px rgb(0, 0, 0, 0.2);
}
.foto-db-perfil {
}
.foto-perfil-mini {
  width: 45px !important;
  height: 45px !important;
  transition: 0.5s ease-in-out;
  border-radius: 50%;
}
.header-sidebar p {
  font-weight: 400;
  animation: 1s texto-header-animation forwards ease-in;
  opacity: 0;
  animation-delay: 0.5s;
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 0;
  letter-spacing: 0.5px;
}
.header-sidebar p:nth-child(3) {
  font-size: 11px;
  text-align: center;
  max-width: 170px;
  /* text-decoration-line: underline; */
}

@keyframes texto-header-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.listitem {
  height: 40px;
  display: flex;
  align-items: center;
  transition: 0.5 all ease-out;
  /* border-top: 1px solid #3b3a46 !important; */
  cursor: pointer;
}

.listitem:nth-child(6) {
  list-style: none;
  height: 40px;
  display: flex;
  align-items: center;

  /* border-top: 1px solid #3b3a46;
  border-bottom: 1px solid #3b3a46; */
}
.sidebar-body {
  height: 100%;
}
.sidebar-body ul {
  margin: 0;
  padding: 0;
}

.sidebar-body ul img {
  width: 25px;
  height: 25px;
}
.txt-sidebar {
  font-size: 13px;
  font-weight: 200;
  margin-left: 10px;
  color: #8f8e97;
  opacity: 1;
  animation: 1s texto-header-animation forwards ease-in;
  opacity: 0;
  animation-delay: 0.5s;
  letter-spacing: 0.5px;
}
.txt-sidebar2 {
  font-size: 13px;
  font-weight: 200;
  margin-left: 10px;
  color: #8f8e97;
  opacity: 1;
  animation: 1s texto-header-animation forwards ease-in;
  opacity: 0;
  animation-delay: 0.6s;
  letter-spacing: 0.5px;
}
.txt-sidebar3 {
  font-size: 13px;
  font-weight: 200;
  margin-left: 10px;
  color: #8f8e97;
  opacity: 1;
  animation: 1s texto-header-animation forwards ease-in;
  opacity: 0;
  animation-delay: 0.7s;
  letter-spacing: 0.5px;
}
.txt-sidebar4 {
  font-size: 13px;
  font-weight: 200;
  margin-left: 10px;
  color: #8f8e97;
  opacity: 1;
  animation: 1s texto-header-animation forwards ease-in;
  opacity: 0;
  animation-delay: 0.8s;
  letter-spacing: 0.5px;
}
.txt-sidebar5 {
  font-size: 13px;
  font-weight: 200;
  margin-left: 10px;
  color: #8f8e97;
  opacity: 1;
  animation: 1s texto-header-animation forwards ease-in;
  opacity: 0;
  animation-delay: 0.9s;
  letter-spacing: 0.5px;
}
.txt-sidebar6 {
  font-size: 13px;
  font-weight: 200;
  margin-left: 10px;
  color: #8f8e97;
  opacity: 1;
  animation: 1s texto-header-animation forwards ease-in;
  opacity: 0;
  animation-delay: 1s;
  letter-spacing: 0.5px;
}

.txt-sidebar-mini {
  font-size: 13px;
  font-weight: 200;
  margin-left: 10px;
  color: #8f8e97;
  opacity: 0;
}

.link-sidebar {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
}

.li-active {
  background: #204371 !important;

  transition: 0.5 ease-out;
  height: 40px;
}

.li-active p {
  color: white;
  font-weight: 400;
  letter-spacing: 0.5px;
}
/* .li-active.first-item{
 margin-top: 30px;
}
.listitem.first-item{
  margin-top: 30px;
} */
@keyframes link-animation {
  0% {
  }
  100% {
  }
}

.btn-cerrar-sesion {
  position: absolute !important;
  bottom: 0;

  border-top: 1px solid #3b3a46 !important;
}

.list-sidebar {
  height: 100%;
  /* background-color: royalblue; */
}

.link-header-sidebar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  /* justify-content: center; */
  align-items: center;
  /* background-color: sandybrown; */
  transition: 0.5s ease-in-out !important;
}
.link-header-mini {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: 0.5s ease-in-out !important;
}
.exitapp-sidebar {
  color: #8f8e97;
  margin-top: 8px;
}
@media (max-width: 959px) {
  .cont-sidebar {
    display: none;
  }
  .cont-sidebar-mini {
    display: none;
  }
}
