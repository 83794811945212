.cont-card-adn_mantenedor {
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.15);
  width: 100%;
  margin-left: -10px;
  margin-top: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
  overflow: hidden;
  background-color: var(--white);
}
.plantilla-card-adn {
  width: 100%;
  height: 95px;
}
.cont-mod-adn {
  background: var(--degradado-blue);
  width: 80px;
  height: 80px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.name-submod-large {
  /* Starting position */
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  transform: translateX(0);
  /* Apply animation to this element */
  -moz-animation: scroll-left 7s linear infinite;
  -webkit-animation: scroll-left 7s linear infinite;
  animation: scroll-left 7s linear infinite;
}
.cont-mod-adn p {
  margin: 0;
  font-size: 24px;
}

.p1-card-adn {
  color: var(--blue-primary);
  margin: 0;
  font-size: 12px;
  max-height: 40px;

  max-width: 380px;
  overflow: auto;
  font-weight: 500;
  margin: 2px 0;
}
.cont-cards-mod-submod {
  overflow: auto;
  width: 100%;
  /* background-color: burlywood; */
  max-height: 200px;
  min-height: 200px;
  margin-bottom: 60px;
}
.cont-cards-mod-submod::-webkit-scrollbar-track {
  margin-top: 10px;
}
.cont-adn-submod {
  display: flex;
  flex-direction: column;
  background-color: #f8f8fb;

  border-radius: 5px;
  margin: 10px 10px 10px 0;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  /* width: 100%; */
}

.top-adn-submod {
  display: flex;
  /* background-color: skyblue; */
  padding-bottom: 10px;
}

.name-adn-submod {
  width: 55px;
  height: 55px;
  background: var(--degradado-blue);
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  overflow: hidden;
}

.name-adn-submod p {
  margin: 0;
  font-size: 16px;
}

.p1-adn-submodulo {
  margin: 0;
  margin-top: 10px;
  color: var(--blue-primary);
  font-weight: 600;
  max-width: 300px;
}
.p1-card-adn-desc {
  color: var(--blue-primary);
  margin: 0;
  font-size: 26px;
  font-weight: 200;
  max-width: 250px;
  margin-top: 10px;
  text-align: center;
}
.cont-nivel-adn-submodulo {
  padding: 8px 15px;
  background-color: #4bacef;
  display: inline-flex;
  border-radius: 3px;
  margin-top: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
}

.cont-nivel-adn-submodulo p {
  margin: 0;
  font-size: 12px;
}

.p2-adn-submodulo {
  color: #4bc1f4;
  margin: 0;
  margin-left: 10px;
  font-weight: 600;
  font-size: 14px;
}

.p3-adn-submodulo {
  color: var(--gray-text);
  font-size: 12px;
  font-style: italic;
  margin: 10px;
  padding-right: 10px;
  max-height: 50px;
  overflow: auto;
}

.cont-btns-card-adn {
  width: 100%;
  position: absolute;
  padding: 20px;
  display: flex;
  bottom: 0;
  justify-content: space-between;
  /* background-color: blueviolet; */
}

.one {
  background-color: var(--blue-primary) !important;
}

.two {
  background-color: var(--blue-primary) !important;
  margin-left: 5px !important;
}
.btns-card-adn {
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  color: var(--white) !important;
  padding: 7px !important;
}
.p4-card-adn {
  color: #4bacef;
  font-weight: 500;
  margin: 0;
  margin-top: 20px;

  margin-bottom: 10px;
}

.p5-card-adn {
  color: var(--gray-text);
  font-size: 12px;
  text-align: center;
  padding: 0 20px;
  margin: 0;
  font-style: italic;
  margin-bottom: 20px;
  max-height: 40px;

  max-width: 380px;
  overflow: auto;
}
