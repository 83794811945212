.container{
  padding-left: 2vw;
  padding-top: 6vw;
}
  

  .datosPersonales{
    width: 16vw;
    height: 70vh;
    display: inline-block;
    overflow: scroll;
    padding: 10px;
    margin: 10px;
    
  }
  
  .estudios{
    width: 16vw;
    height: 70vh;
    display: inline-block;
    overflow: scroll;
    padding: 10px;
    margin: 10px;
  }
  .adnSap{
    width: 20vw;
    height: 70vh;
    display: inline-block;
    overflow: scroll;
    padding: 10px;
    margin: 10px;
  }
  .certificados{
    width: 16vw;
    height: 70vh;
    display: inline-block;
    overflow: scroll;
    padding: 10px;
    margin: 10px;
  }
  .trabajos{
    width: 16vw;
    height: 70vh;
    display: inline-block;
    overflow: scroll;
    padding: 10px;
    margin: 10px;
  }