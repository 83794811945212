.conteiner-cards-certificados {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 40px 40px 20px 0;
}

.cont-title-certificados {
  padding-top: 100px;
  padding-left: 60px;
  /* background-color: salmon; */
}

.cont-title-certificados h1 {
  margin: 0;
  color: var(--blue-primary);
  font-weight: 400;
}

@media only screen and (min-width: 1279px) {
  .icon-btn-add-trabajos{
    right: 50%;
    top:100px
  } 
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1279px) and (max-width: 1619px) {
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
  .icon-btn-add-trabajos{
    right: 14%;
    top:100px
  } 
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
  .conteiner-cards-certificados {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 60px 0px 80px 0px;
  }
  .cont-title-certificados {
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 30px;
    padding-bottom: 10px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 999;
    width: 100%;
    box-shadow: 1px 0 5px 1px rgba(0, 0, 0, 0.2);
  }
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .conteiner-cards-certificados {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 60px 0px 80px 0px;
  }
  .cont-title-certificados {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 0px;
    padding-bottom: 10px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 999;
    width: 100%;
    box-shadow: 1px 0 5px 1px rgba(0, 0, 0, 0.2);
  }
}

@media only screen and (max-width: 500px) {
}
