.customInputFile {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.customInputFile__label {
  font-family: "Roboto";
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
}

.customInputFile__input {
  display: flex;
  flex-direction: row;
}

.customInputFile__input-hidden {
  display: none;
}

.customInputFile__input-cv {
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  width: 100%;
}

.customInputFile__input-cv-icon {
  float: right;
  cursor: pointer;
}
