.cont-card-estudio {
  background-color: var(--white);
  border-radius: 5px;
  position: relative;
  margin: 5px;
  margin-left: 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 20px 20px 30px 20px;
  align-items: center;
  height: 500px;
  width: 100px;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.15);
}

.cont-img-card-estudio {
  background-color: #f5f5f5;
  border-radius: 50%;
  /* box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2); */
  display: flex;
  border: 1px solid var(--gris-2);
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}

.cont-img-card-estudio img {
  width: 100%;
}

.cont-promedio-estudios {
  position: absolute;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 30px;
}

.cont-promedio-estudios p {
  margin: 0;
  color: var(--gray-text);
}

.cont-promedio-estudios p:nth-child(1) {
  font-size: 20px;
  font-weight: 600;
}

.cont-promedio-estudios p:nth-child(2) {
  font-size: 12px;
  font-weight: 500;
}

.p1-card-estudio {
  color: var(--blue-primary);
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
}
.p2-card-estudio {
  color: var(--blue-primary);
  margin: 0;
  font-size: 26px;
  font-weight: 200;
  max-width: 300px;
  text-align: center;
  /* text-transform: lowercase ; */

  /* margin-top: 5px; */
}
.p3-card-estudio {
  color: var(--blue-primary);
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  margin-top: 3px;
  text-align: center;
}
.p4-card-estudio {
  color: var(--blue-primary);
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  margin-top: 3px;
}
.p5-card-estudio {
  /* margin: 0;
  color: var(--gray-text);
  font-size: 12px;
  margin-top: 3px; */
  color: var(--light-blue);
  margin: 0;
  font-size: 12px;
  margin: 2px 0;
}

.cont-box-card-estudio {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
}
.box-top-estudio {
  display: flex;
  justify-content: center;
}
/*37CBBA*/
.sub-box-estudio-1 {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);

  background-color: #37cbba;
}
.sub-box-estudio-2 {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  margin-left: 10px;
  background-color: var(--blue-primary);
}
.sub-box-estudio-3 {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  background-color: #49d5ef;
}

.sub-box-estudio-1 p {
  margin: 0;
  font-size: 12px;
}
.sub-box-estudio-2 p {
  margin: 0;
  font-size: 12px;
}
.sub-box-estudio-3 p {
  margin: 0;
  font-size: 12px;
}
.cont-obs-card-estudio p {
  margin: 0;
  font-size: 12px;
  color: var(--gray-text);
  max-height: 60px;
  overflow: auto;
  font-style: italic;
  padding-right: 10px;
  text-align: center;
}

.cont-obs-card-estudio {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 40px;
  width: 100%;
}

.cont-icon-btns-estudio {
  width: 85px;
  display: flex;
  justify-content: space-between;
}

.cont-btns-card-est-b2 {
  /* background-color: lightcoral; */
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  bottom: 20px;
  padding: 0 20px;
}
/* MEDIA QUERIES */
@media only screen and (min-width: 960px) and (max-width: 1500px) {
  .cont-card-estudio {
    margin-left: 10px;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
  .cont-card-estudio {
    width: 100%;
    /* height: 30%; */
  }
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .cont-card-estudio {
    /* background-color: red; */
    width: 90%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 500px) {
}

@media only screen and (max-width: 360px) {
  .cont-card-estudio {
    /* background-color: red; */
    width: 98%;
  }
}
