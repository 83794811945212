.cont-card-habilidades {
  background-color: var(--white);
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.cont-card-habilidades h1 {
  color: var(--gray-text);
  font-size: 14px;
  margin: 0 0 0 10px;
  flex: 1;
}
.sub-cont-card-habilidades {
  flex: 1;
  display: flex;
  align-items: center;
  /* background-color: seagreen; */
}
.cont-names-habilidades {
  display: flex;
  /* background-color: red; */
  flex-wrap: wrap;
  max-height: 90px;
  margin-right: 50px;
  overflow: auto;
  padding: 0 20px 0 0;
  flex: 2;
}
.cont-names-habilidades div {
  padding: 10px 20px;
  margin-left: 10px;
  margin-bottom: 5px;

  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cont-names-habilidades p {
  margin: 0;

  font-size: 14px;
}

.icon-btn-edit-hab {
  position: absolute !important;
  color: var(--white) !important;
  background-color: var(--blue-primary) !important;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  /* color: ; */
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1279px) and (max-width: 1619px) {
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .cont-card-habilidades {
    height: 150px;
  }
}

@media only screen and (max-width: 500px) {
}
