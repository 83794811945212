.cont-avisos-emp {
  padding-top: 75px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  /* background-color: aqua; */
  max-width: 1400px;
}

.cont-header-avisos-emp {
  /* background-color: blue; */
  padding-right: 25px;
  padding-bottom: 10px;
  padding-left: 10px;
}
.cont-pagination-avisos-emp {
  margin-top: 10px;
}
.cont-cards-avisos-emp {
  /* background-color: brown; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 60px;
  max-height: 81vh;
  height: 80vh;
  overflow: auto;
  padding-right: 20px;
  padding-left: 10px;
}

.div-cargando-avisos {
  width: 100%;
  display: flex;
  justify-content: center;
}

.div-cargando-avisos p {
  color: var(--gray-text);
}

.no-avisos-msg-emp {
  width: 100%;
  height: 100%;
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-avisos-msg-emp p {
  color: var(--blue-primary);
  font-size: 30px;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1280px) and (max-width: 1619px) {
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
  .cont-header-avisos-emp {
    /* background-color: blue; */
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
  }
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .cont-header-avisos-emp {
    /* background-color: blue; */
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
  }
  .cont-cards-avisos-emp {
    padding-right: 10px;
  }
  .cont-cards-avisos-emp::-webkit-scrollbar-track {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 500px) {
}
