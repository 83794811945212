.cont-footer-card-perfil {
  /* background-color: brown; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* padding-right: 20px; */
  padding: 5px 0;
}

.speed-dial-footer-perfil {
  position: absolute;
  right: 10px;
  top: -2px;
}

.speed-dial-action {
  background-color: white !important;
  color: var(--blue-primary) !important;
  transition: 0.5s all ease-in-out;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.2) !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
}
.speed-dial-action:hover {
  background-color: var(--light-blue) !important;
  color: var(--white) !important;
  transition: 0.5s all ease-in-out;
}
.speed-dial-action-active {
  background-color: var(--blue-primary) !important;
  color: var(--white) !important;
  transition: 0.5s all ease-in-out;
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.cont-btns-rrss-perfil-close {
  opacity: 1;
  transition: 0.5s all ease-in-out;
  /* background-color: seagreen; */
}

.cont-btns-rrss-perfil-open {
  opacity: 0;
  transition: 0.5s all ease-in-out;
}

.btn-red-social-perfil {
  border: 1px solid var(--light-blue) !important;
  width: 45px;
  height: 45px;
  margin-right: 10px !important;
  color: var(--light-blue) !important;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.1) !important;
  transition: 0.5s all ease-in-out;
}

.btn-red-social-perfil:hover {
  /* border: 1px solid var(--light-blue) !important;
  width: 45px;
  height: 45px; */
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.3) !important;
  margin-right: 10px !important;
  color: var(--white) !important;
  background-color: var(--light-blue) !important;
  transition: 0.7s all ease-in-out;
}

/*new*/
.btn-red-social-perfil-new {
  background-color: white !important;
  width: 40px !important;
  height: 40px !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.icon-red-social-rrss-perfil-new {
  color: var(--blue-primary) !important;
}
.administrar-redes-sociales-btn {
  position: relative;

  width: 72px;
  height: 50px;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1279px) and (max-width: 1619px) {
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .administrar-redes-sociales-btn {
    position: relative;
    width: 50px;
    /* background-color: lawngreen; */
  }
  .speed-dial-footer-perfil {
    position: absolute;
    right: 2px;
    top: -2px;

    /* background-color: rgb(129, 116, 189); */
  }
}
