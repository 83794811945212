.cont-filtro-eco-sap-emp {
  background-color: var(--white);
  width: 300px;
  height: 100%;
  border-radius: 5px;
  box-shadow: 1px 5px 20px rgba(0, 0, 0, 0.15);
  padding: 15px 0;
  position: relative;

  min-height: 840px;
}

.cont-filtro-eco-sap-emp h1 {
  color: var(--blue-primary);
  font-size: 22px;
}

.cont-filtro-eco-sap-emp .title-filtros-eco-sap {
  /* background-color: rosybrown; */
  height: 40px;
  padding: 0 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d9d6d5;
}

.cont-filtro-eco-sap-emp .item {
  padding: 5px 20px;
}

.cont-filtro-eco-sap-emp .item p {
  color: var(--light-blue);
  font-size: 14px;
  margin-bottom: 5px;
}

.cont-filtro-eco-sap-emp .item-bottom {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
  position: absolute;
  bottom: 15px;
}
