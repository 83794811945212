.cont-one-adn-nuevo {
  background-color: var(--white);
  width: 100%;
  height: auto;
  border-radius: 5px;
  align-items: center;
  position: relative;
  margin-left: -20px;
  padding: 10px 20px;
}

.cont-one-adn-edit-man {
  background-color: var(--white);
  width: 600px;
  max-width: 700px;
  height: auto;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 20px 60px;
}

.cont-one-adn-edit-man h1 {
  color: var(--blue-primary);
  text-align: left;
  margin: 20px 0 30px 0;
  font-weight: 200;
  font-size: 24px;
}

.cont-btns-one-adn-edit {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 15px 0px;
}



.cont-btn-adn-one-edit {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  /* position: absolute; */
  width: 100%;
  /* margin-bottom: 40px; */
}

.cont-three-adn-edit {
  background-color: var(--white);
  position: relative;
  width: 98%;
  max-width: 500px;
  border-radius: 5px;
  padding: 10px 20px 30px 20px;
}
.step-one-top-adn.addsm {
  width: 100%;
}

.cont-sub-modulos-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 1px 6px 15px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 1px 6px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 6px 15px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  width: 93%;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.cont-sub-modulos-mod {
  text-align: center;
  color: var(--blue-primary);
  padding: 5px 0 10px 0;
  margin: 0px;
  font-size: 20px;
}

.cont-sub-modulos-descp {
  text-align: center;
  color: var(--gray-text);
  padding: 0px;
  margin: 0px;
  font-size: 12px;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1279px) and (max-width: 1619px) {
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
}



.cont-cards-adn_mantenedor {
  width: 20vw !important;
  /* background-color: aquamarine; */
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 40px 40px 20px 5px;
  justify-content: space-around;
  margin-left: -2vw;
}

.cont-btn-add-adn {
  position: absolute;
  right: 10px;
}

.btn-add-adn {
  color: white !important;
  background-color: var(--blue-primary) !important;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}

.agregar-form{
  width: 80px !important;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1279px) and (max-width: 1619px) {
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
  .cont-cards-adn_mantenedor {
    justify-content: space-evenly;
    padding: 60px 0px 80px 0px;
  }
  .cont-btn-add-adn {
    position: absolute;
    right: 10px;
    top: -40px;
  }
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .cont-cards-adn_mantenedor {
    justify-content: space-evenly;
    padding: 60px 0px 80px 0px;
  }
  .cont-btn-add-adn {
    position: absolute;
    right: 10px;
    top: -40px;
  }
}

@media only screen and (max-width: 2000px) {
  /*ONE*/
  .cont-one-adn-edit-man {
    background-color: var(--white);
    width: 100%;
    max-width: 700px;
    border-radius: 5px;
    display: flex;
    align-items: left;
    flex-direction: column;
    position: relative;
    margin-left: -60px;
  }

  .cont-one-adn-edit-man.no-margin {
    margin: 0;
    padding: 0px 10px 0px 5px;
  }

  .sub-cont-SwipeableViews-active {
    width: 60px;
    height: 60px;
  }
  .sub-cont-SwipeableViews {
    width: 60px;
    height: 60px;
  }

  .content-center-adn {
    width: 230px;
    flex: 5;
  }

  .step-one-top-adn.addsm {
    display: flex;
    flex: 5;
    max-width: 500px;
    min-width: inherit;
  }
  .cont-btns-one-adn-edit {
    padding: 0 10px;
  }
  /*TWO*/
  .sub-nivel-adn-active {
    width: 60px;
    height: 60px;
    transform: scale(1.1);
  }
  .sub-nivel-adn {
    width: 60px;
    height: 60px;
  }
}
