.conteiner-cards-estudio {
  width: 100%;
  /* min-height: 100vh; */
  /* background-color: aquamarine; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  padding: 40px 40px 20px 5px;
}
.cont-card-init-estudios {
  padding-top: 75px;
  max-height: 100vh;
}
@media only screen and (min-width: 1279px) {
  .icon-btn-add-trabajos{
    right: 50%;
    top:100px
  } 
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1279px) and (max-width: 1619px) {
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
  .icon-btn-add-trabajos{
    right: 14%;
    top:100px
  } 
}


/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
  .conteiner-cards-estudio {
    /* background-color: lightcoral; */
 
    justify-content: space-evenly;
    padding: 60px 0px 80px 0px;
  }

  .icon-btn-add-trabajos {
    position: relative !important;
    /* margin-top: 10px !important; */
  }
}
 
/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .conteiner-cards-estudio {
    /* background-color: lightcoral; */
    padding: 60px 0px 80px 0px;
    justify-content: center;
  }
  .icon-btn-add-trabajos {
    position: relative !important;
    /* margin-top: 10px !important; */
  }
  .cont-card-init-estudios {
    padding-top: inherit;
    height: auto;
  }
}

@media only screen and (max-width: 500px) {
}
