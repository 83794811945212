.ofertas-laborales {
  padding-top: 75px;
  padding-left: 10px;
  /* max-width: 1500px; */
  position: relative;
  /* height: 100%; */
}
.cont-header-of-laborales-top {
  display: flex;
  padding-left: 10px;
  justify-content: space-between;
}

.empty-header-of {
  /* background-color: saddlebrown; */
  width: 420px;
  min-width: 340px;
  height: 10px;
}
.cont-header-of-laborales {
  margin-bottom: 10px;
  width: 100%;
}

.cont-card-of-laborales {
  /* justify-content: space-around; */
  padding-bottom: 60px;
  max-height: 81vh;
  overflow: auto;
  padding-right: 10px;
  margin-right: 10px;
  padding-left: 10px;
  overflow-x: visible;
  /* background-color: cadetblue; */
  width: 100%;
}
.icon-page-header-user {
  color: var(--blue-primary) !important;
  width: 30px !important;
  height: 30px !important;
  margin-top: 3px;
}

.cont-cards-filtro-ofertas {
  /* background-color: blue; */
  height: 81vh;
  padding-right: 20px;
  display: flex;
}

.cont-filtro-of-laborales {
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 5px 40px rgba(0, 0, 0, 0.15);
}
.cont-btn-filtrar-of-user {
  display: none;
}

@media only screen and (max-width: 1750px) {
  .cont-filtro-of-laborales {
    display: none;
  }
  .cont-btn-filtrar-of-user {
    display: inherit;
  }
  /* .cont-header-of-laborales-top {
    padding-right: 40px;
  } */
  .empty-header-of {
    display: none;
  }
  .ofertas-laborales {
    max-width: 1500px;
  }
  .cont-header-of-laborales {
    margin-right: 20px;
  }
  .cont-cards-filtro-ofertas {
    padding-right: 10px;
  }
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1280px) and (max-width: 1619px) {
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
  .cont-card-of-laborales {
    max-height: 77vh;
  }
  .cont-cards-filtro-ofertas {
    height: 77vh;
  }
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
  .cont-header-of-laborales {
    margin-right: 20px;
  }
  .cont-header-of-laborales-top {
  
  }
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .ofertas-laborales {
    padding-top: 75px;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
  }
  .cont-cards-filtro-ofertas {
    padding-right: 0px;
    display: flex;
  }
  .cont-header-of-laborales-top {
    padding-left: 5px;
    padding-right: 0px;
    margin-right: 10px;
   
  }
  .cont-card-of-laborales {
    padding-right: 5px;
    margin-right: 0px;
    padding-left: 5px;
    /* background-color: rosybrown; */
  }
  .cont-card-of-laborales::-webkit-scrollbar-track {
    margin-bottom: 100px;
    margin-top: 10px;
  }
  .cont-header-of-laborales {
    margin-bottom: 10px;
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 500px) {
}
