@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");
:root {
  --white: #ffffff;
  --blue-primary: #187ce2;
  --blue-secondary: #1d72de;
  --blue-extralight: #f3f8fe;
  --blue-dark: #2d54cc;
  --blue-sidebar: #204371;
  --body-sidebar: #252435;
  --light-blue: #4bacef;
  --light-blue-3: #bae1f2;
  --blue-title: #2c52cc;
  --gray-light: #e5ecef;
  --gray-text: #7d7f80;
  --light-blue-2: #5ca1ea;
  --green: #1b9d62;
  --gray-blue: #7d9de3;
  --gris: #686969;
  --gris-2: #dbdbdb;
  --error: #f44336;
  --orange: #FF861B;
  --main-bg: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 68%,
    rgba(214, 236, 251, 1) 100%
  );
  --degradado-blue: linear-gradient(
    90deg,
    rgba(21, 134, 234, 1) 0%,
    rgba(46, 74, 199, 1) 100%
  );
  --degradado-gray: linear-gradient(
    90deg,
    rgba(202, 214, 226, 1) 0%,
    rgba(141, 145, 158, 1) 100%
  );
  --degradado-empresas: linear-gradient(
    90deg,
    rgba(248, 152, 29, 1) 0%,
    rgba(61, 83, 165, 1) 100%
  );
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

label,
h2,
h3,
h4,
h5,
h6 {
  color: var(--white);
  font-family: "Roboto";
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
p {
  /* color: var(--white); */
  font-family: "Roboto";
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
h1 {
  font-family: "Roboto";
  margin: 0;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
textarea {
  font-family: "Roboto";
  margin: 0;
}
body {
  padding: 0;
  margin: 0;
  background: var(--main-bg);
  height: 100%;
}
.link {
  text-decoration: none;
  cursor: pointer;
}

.icon-btn-add-trabajos {
  position: absolute !important;
  right: 10px;
  background-color: var(--blue-primary) !important;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2) !important;
  color: var(--white) !important;
}

.custom-menu-item {
  font-size: 14px !important;
  color: #7d7f80 !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  padding-right: inherit !important;
  padding-left: inherit !important;
  max-width: 400px !important;
  overflow: auto !important;
}

/**
components:
-boton completar
-card
colors:

*/
/*menu item*/

.custom-menu-item::-webkit-scrollbar {
  -webkit-appearance: none;
}

.custom-menu-item::-webkit-scrollbar:vertical {
  width: 7px;
}

.custom-menu-item::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

.custom-menu-item::-webkit-scrollbar:horizontal {
  height: 5px;
}

.custom-menu-item::-webkit-scrollbar-thumb {
  background-color: #4bacef;
  border-radius: 20px;
}

.custom-menu-item::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #dbeefc;
}
/*P*/
p::-webkit-scrollbar {
  -webkit-appearance: none;
}

p::-webkit-scrollbar:vertical {
  width: 7px;
}

p::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

p::-webkit-scrollbar:horizontal {
  height: 7px;
}

p::-webkit-scrollbar-thumb {
  background-color: #dbdbdb;
  border-radius: 20px;
}

p::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ededee;
}

/*DIV*/

div::-webkit-scrollbar {
  -webkit-appearance: none;
}

div::-webkit-scrollbar:vertical {
  width: 7px;
}

div::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

div::-webkit-scrollbar:horizontal {
  height: 5px;
}

div::-webkit-scrollbar-thumb {
  background-color: #4bacef;
  border-radius: 20px;
}

div::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #dbeefc;
}

/*TEXTAREA*/

textarea::-webkit-scrollbar {
  -webkit-appearance: none;
}

textarea::-webkit-scrollbar:vertical {
  width: 7px;
}

textarea::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
  display: none;
}

textarea::-webkit-scrollbar:horizontal {
  height: 10px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #dbdbdb;
  border-radius: 20px;
}

textarea::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ededee;
}
