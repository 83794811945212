.input-label-custom-select {
  font-size: 12px !important;
}
.select-custom-select-b {
  font-size: 12px !important;
}


.helper-text-custom-select-b{
  font-size: 11px !important;
  margin: 0 !important;
}