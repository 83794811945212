.cont-header-home-b {
  background-color: white;
  height: 100%;
  border-radius: 5px;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.15);
  display: flex;
}

.cont-left-header-home {
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 35px;
}

.cont-right-header-home {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 35px;
  align-items: center;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 1279px) and (max-width: 1619px) {
}

@media only screen and (min-width: 960px) and (max-width: 1279px) {
}

/* Smartphones in landscape mode */

@media only screen and (min-width: 600px) and (max-width: 959px) {
}

/* Smartphones in portrait mode  */

@media only screen and (max-width: 599px) {
  .cont-header-home-b {
    height: 40px;
  }
  .cont-left-header-home {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 500px) {
}
